import React, { useMemo } from "react";
import { t } from "ttag";

import { Link } from "../../../common/Link";
import {
    FinancingCardType,
    FinancingModalTriggerTheme,
} from "../../../constants";
import { IWebPageURL } from "../../../models/nominals";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { getDefaultFinancingPlan } from "../../../utils/financing";
import { formatFinancingPlan } from "../../../utils/format";
import { FinancingCardImage } from "../FinancingCardImage";
import { FinancingModalTrigger } from "../FinancingModalTrigger";
import { Content } from "./Content";

import styles from "./FinancingModalTriggerBlock.module.scss";

interface Props {
    cardType: FinancingCardType;
    applicationSource?: string;
    theme?: FinancingModalTriggerTheme;
    aprInfoID?: string | null;
    financeLink?: IWebPageURL;
}

export const FinancingModalTriggerBlock = (props: Props) => {
    const defaultPlan = useMemo(getDefaultFinancingPlan, []);
    const formattedPlan = useMemo(
        () => formatFinancingPlan(defaultPlan),
        [defaultPlan],
    );

    const content = defaultPlan ? (
        <Content
            plan={defaultPlan}
            theme={props.theme}
            aprInfoID={props.aprInfoID}
            applicationSource={props.applicationSource}
        />
    ) : null;

    const isMobileWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.MEDIUM;
    };

    if (!formattedPlan) {
        return <></>;
    }

    return (
        <div className={`pre-approval-ad__block-container ${styles.root}`}>
            <div>
                <span className={styles.imageContainer}>
                    <FinancingCardImage
                        className={styles.cc}
                        cardType={props.cardType}
                    />
                    {props.theme === FinancingModalTriggerTheme.CARD_OVERLAP ||
                    props.theme === FinancingModalTriggerTheme.SPECIAL ? (
                        <div>{content}</div>
                    ) : (
                        <></>
                    )}
                    {isMobileWidth() && (
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            className={`button pre-approval-ad__button al-pre-approval-ad--pre-qualify al-pre-approval-ad--pre-qualify-desktop ${styles.button}`}
                            applicationSource={props.applicationSource}
                        >
                            {t`Pre-Qualify`}
                        </FinancingModalTrigger>
                    )}
                </span>

                <div className={`pre-approval-terms ${styles.terms}`}>
                    <div>
                        <p>
                            {t`${formattedPlan.apr} APR for ${formattedPlan.length} Months`}
                            {props.financeLink && (
                                <sup>
                                    <Link href={props.financeLink}>
                                        {formattedPlan.superscript}
                                        <span className="ada-screenreader-only">
                                            {t`Additional information about Terms and Conditions`}
                                        </span>
                                    </Link>
                                </sup>
                            )}
                        </p>

                        <p>{t`with minimum purchase of ${formattedPlan.threshold}`}</p>
                        <p>
                            {t`Check if you pre-qualify without affecting your credit score.`}
                        </p>
                    </div>
                    {!isMobileWidth() && (
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            className={`button pre-approval-ad__button al-pre-approval-ad--pre-qualify al-pre-approval-ad--pre-qualify-desktop ${styles.button}`}
                            applicationSource={props.applicationSource}
                        >
                            {t`Pre-Qualify`}
                        </FinancingModalTrigger>
                    )}
                </div>
            </div>
        </div>
    );
};
